import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { pagesListingApi } from "src/DAL/WebsitePages/websitepages";
import { project_url } from "src/config/config";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";

const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "sale_page_title", label: "Page Title", alignRight: false },
  {
    id: "preview_link_data",
    label: "URL",
    type: "link",
    alignRight: false,
  },
  {
    id: "action",
    label: "Action",
    alignRight: false,
    MENU_OPTIONS: "MENU_OPTIONS",
    type: "action",
  },
];

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function WebsitePages() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [templateData, setTemplateData] = useState([]);
  const is_website_pages = window.location.pathname.includes("website-pages");

  const getTemplatePagesListing = async () => {
    setIsLoading(true);
    const result = await pagesListingApi(
      is_website_pages ? "general" : "template"
    );
    if (result.code === 200) {
      console.log(result, "menu handlings");
      let result_array = [];
      result.Sale_page.map((template) => {
        result_array.push({
          ...template,
          preview_link_data: {
            to: project_url + template.sale_page_title_slug,
            target: "_blank",
            show_text: "Preview",
            className: "anchor-style",
          },
          MENU_OPTIONS: menuHandling(template),
        });
      });
      setTemplateData(result_array);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleEdit = (value) => {
    console.log(value, "valueeee");
    delete value.MENU_OPTIONS;
    if (is_website_pages) {
      navigate(`/website-pages/edit-page/${value.sale_page_title_slug}`, {
        state: value,
      });
    } else {
      navigate(`/template-pages/edit-page/${value.sale_page_title_slug}`, {
        state: value,
      });
    }
  };

  const handleUpdatePageContent = (value) => {
    delete value.MENU_OPTIONS;
    if (is_website_pages) {
      navigate(
        `/website-pages/update-page-content/${value.sale_page_title_slug}`,
        {
          state: value,
        }
      );
    } else {
      navigate(
        `/template-pages/update-page-content/${value.sale_page_title_slug}`,
        {
          state: value,
        }
      );
    }
  };

  const handleAccess = (value) => {
    delete value.MENU_OPTIONS;
    navigate(
      `/template-pages/manage-consultant-access/${value.sale_page_title_slug}`,
      {
        state: value,
      }
    );
  };

  const handleModuleAccess = (value) => {
    delete value.MENU_OPTIONS;
    navigate(
      `/template-pages/manage-module-access/${value.sale_page_title_slug}`,
      {
        state: value,
      }
    );
  };

  const handleRedirect = (module, page_id, value) => {
    if (is_website_pages) {
      switch (module.module_actual_name) {
        case "testimonial":
          navigate(`/website-pages/sale-testimonial/${page_id}`, {
            state: { module, value },
          });
          break;
        case "website_events":
          navigate(`/website-pages/sale-page-event/${page_id}`, {
            state: module,
          });
          break;
        case "payment_plans":
          navigate(`/website-pages/payment-plans/${page_id}`, {
            state: module,
          });
          break;
        case "website_programmes":
          navigate(`/website-pages/${page_id}/website-programmes`, {
            state: module,
          });
          break;
        case "buisness_strategy":
          navigate(`/website-pages/${page_id}/business-strategy`, {
            state: module,
          });
          break;
        case "website_faq":
          navigate(`/website-pages/${page_id}/website-faq`, {
            state: module,
          });
          break;
        case "business_strategy":
          navigate(`/website-pages/business-strategy/${page_id}`, {
            state: module,
          });
          break;
        case "business_partner":
          navigate(`/website-pages/business-partner/${page_id}`, {
            state: module,
          });
          break;
        case "team":
          navigate(`/website-pages/team/${page_id}`, {
            state: module,
          });
          break;
        case "services":
          navigate(`/website-pages/service/${page_id}`, {
            state: module,
          });
          break;
        case "banner_slider":
          navigate(`/website-pages/banner-slider/${page_id}`, {
            state: module,
          });
          break;
        case "book_category":
          navigate(`/website-pages/book-categories/${page_id}`, {
            state: module,
          });
          break;
        case "website_programme_categories":
          navigate(`/website-pages/website-categories/${page_id}`, {
            state: module,
          });
          break;

        case "wheel_of_life":
          navigate(`/website-pages/${page_id}/questions`, {
            state: module,
          });
          break;
        default:
        // code block
      }
    } else {
      switch (module.module_actual_name) {
        case "testimonial":
          navigate(`/template-pages/${page_id}/testimonials`, {
            state: module,
          });
          break;
        case "payment_plans":
          navigate(`/template-pages/${page_id}/payment-plans`, {
            state: module,
          });
          break;
        case "website_programmes":
          navigate(`/template-pages/${page_id}/website-programmes`, {
            state: module,
          });
          break;
        case "buisness_strategy":
          navigate(`/template-pages/${page_id}/business-strategy`, {
            state: module,
          });
          break;
        case "website_faq":
          navigate(`/template-pages/${page_id}/website-faq`, {
            state: module,
          });
          break;
        case "wheel_of_life":
          navigate(`/template-pages/${page_id}/questions`, {
            state: module,
          });
          break;
        default:
        // code block
      }
    }
  };

  const menuHandling = (value) => {
    const MENU_OPTIONS = [
      {
        label: "Edit Page Setting",
        icon: "akar-icons:edit",
        handleClick: (e) => {
          handleEdit(e);
        },
      },
      {
        label: "Update Page Content",
        icon: "akar-icons:edit",
        handleClick: handleUpdatePageContent,
      },
    ];
    if (!is_website_pages) {
      MENU_OPTIONS.push(
        {
          label: "Manage Consultant Access",
          icon: "akar-icons:edit",
          handleClick: handleAccess,
        },
        {
          label: "Manage Module Access",
          icon: "akar-icons:edit",
          handleClick: handleModuleAccess,
        }
      );
    }

    value.module_info.map((module) => {
      MENU_OPTIONS.push({
        label: module.module_label_text,
        icon: "akar-icons:edit",
        handleClick: () => {
          handleRedirect(module, value._id, value);
        },
      });
    });
    return MENU_OPTIONS;
  };

  useEffect(() => {
    getTemplatePagesListing();
  }, [is_website_pages]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  const handleNavigate = () => {
    navigate(`/website-pages/add-page`);
  };

  return (
    <>
      <div className="container">
        <div className="row mb-5">
          <div className="col-lg-8 col-sm-12">
            <h2>{is_website_pages ? "Pages" : "Template Pages"}</h2>
          </div>
          {is_website_pages && (
            <div className="col-lg-4 col-sm-12 text-end">
              <button
                onClick={handleNavigate}
                className="small-contained-button"
              >
                Add New Page
              </button>
            </div>
          )}
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={templateData}
          className="card-with-background"
        />
      </div>
    </>
  );
}
