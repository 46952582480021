import * as Yup from "yup";
import { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// material
import { Stack, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";

// ----------------------------------------------------------------------

export default function EmailForm({ onhandleEmailSubmit }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [email, setEmail] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      enqueueSnackbar("Please Enter Email!", { variant: "error" });
      return;
    }
    onhandleEmailSubmit(email);
  };
  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit}>
      <Stack spacing={3}>
        <TextField
          fullWidth
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          autoComplete="username"
          type="email"
          label="Email address"
        />
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: 2 }}
      >
        <LoadingButton fullWidth size="large" type="submit" variant="contained">
          Submit
        </LoadingButton>
      </Stack>
    </form>
  );
}
