import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import TinyEditor from "../../components/ckeditor/Ckeditor";
import {
  addPageApi,
  detailPageApi,
  editPageApi,
  templateListingApi,
} from "src/DAL/WebsitePages/websitepages";
import { s3baseUrl } from "src/config/config";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Input = styled("input")({
  display: "none",
});

export default function AddOrUpdatePage() {
  const navigate = useNavigate();
  const classes = useStyles();
  const params = useParams();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [templates, setTemplates] = React.useState([]);
  const [shortDescriptionCk, setShortDescriptionCk] = React.useState("");
  const [formType, setFormType] = useState("ADD");

  const [images, setImages] = React.useState({
    brand_favicon: "",
    brand_logo: "",
    footer_logo: "",
  });

  const [oldImages, setOldImages] = React.useState({
    brand_favicon: "",
    brand_logo: "",
    footer_logo: "",
  });

  const [inputs, setInputs] = React.useState({
    brand_favicon: {},
    brand_logo: {},
    footer_logo: {},
    meta_title: "",
    meta_keywords: "",
    active_campaign_list_id: "",
    active_campaign_tag_id: "",
    redirect_user: "",
    meta_description: "",
    pixel_code_header: "",
    pixel_code_body: "",
    template: "",
    is_general_brand_logo: false,
    is_general_social_links: true,
    facebook_link: "",
    pinterest_link: "",
    instagram_link: "",
    youtube_link: "",
    mailbox_link: "",
  });

  const fileChangedHandler = (e) => {
    const { name, files } = e.target;
    setInputs({
      ...inputs,
      [name]: files[0],
    });
    setImages({
      ...images,
      [name]: URL.createObjectURL(files[0]),
    });
  };

  const formatData = (data) => {
    setFormType("EDIT");
    const { thankyou_page_content, brand_logo, brand_favicon, footer_logo } =
      data;
    setInputs(data);
    setOldImages({
      brand_logo,
      brand_favicon,
      footer_logo,
    });
    setShortDescriptionCk(thankyou_page_content);
    setIsLoading(false);
  };

  const getPageDetail = async () => {
    setIsLoading(true);
    const result = await detailPageApi(params.page_slug);
    if (result.code == 200) {
      setFormType("EDIT");
      const { thankyou_page_content, brand_logo, brand_favicon, footer_logo } =
        result.sale_page;
      setInputs({
        ...result.sale_page,
        template: result.sale_page.template._id,
      });
      setOldImages({
        brand_logo,
        brand_favicon,
        footer_logo,
      });
      setShortDescriptionCk(thankyou_page_content);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    }
  };

  const templatesListData = async () => {
    setIsLoading(true);
    let result = await templateListingApi();
    if (result.code == 200) {
      setTemplates(result.Template_configuration);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("meta_title", inputs.meta_title);
    formData.append("meta_description", inputs.meta_description);
    formData.append("meta_keywords", inputs.meta_keywords);
    formData.append("pixel_code_header", inputs.pixel_code_header);
    formData.append("pixel_code_body", inputs.pixel_code_body);
    formData.append("active_campaign_list_id", inputs.active_campaign_list_id);
    formData.append("active_campaign_tag_id", inputs.active_campaign_tag_id);
    formData.append("redirect_user", inputs.redirect_user);
    formData.append("template", inputs.template);
    formData.append("sale_page_title", inputs.sale_page_title);
    formData.append("thankyou_page_content", shortDescriptionCk);
    formData.append("is_general_brand_logo", inputs.is_general_brand_logo);
    formData.append("is_general_social_links", inputs.is_general_social_links);
    if (!inputs.is_general_social_links) {
      formData.append("facebook_link", inputs.facebook_link);
      formData.append("pinterest_link", inputs.pinterest_link);
      formData.append("youtube_link", inputs.youtube_link);
      formData.append("instagram_link", inputs.instagram_link);
      formData.append("mailbox_link", inputs.mailbox_link);
    }
    if (images.brand_logo) {
      formData.append("brand_logo", inputs.brand_logo);
    }
    if (images.brand_favicon) {
      formData.append("brand_favicon", inputs.brand_favicon);
    }
    if (images.footer_logo) {
      formData.append("footer_logo", inputs.footer_logo);
    }

    const result = await addPageApi(formData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
      navigate(-1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("meta_title", inputs.meta_title);
    formData.append("meta_description", inputs.meta_description);
    formData.append("meta_keywords", inputs.meta_keywords);
    formData.append("pixel_code_header", inputs.pixel_code_header);
    formData.append("pixel_code_body", inputs.pixel_code_body);
    formData.append("active_campaign_list_id", inputs.active_campaign_list_id);
    formData.append("active_campaign_tag_id", inputs.active_campaign_tag_id);
    formData.append("redirect_user", inputs.redirect_user);
    formData.append("template", inputs.template);
    formData.append("sale_page_title", inputs.sale_page_title);
    formData.append("thankyou_page_content", shortDescriptionCk);
    formData.append("is_general_brand_logo", inputs.is_general_brand_logo);
    formData.append("is_general_social_links", inputs.is_general_social_links);
    if (!inputs.is_general_social_links) {
      formData.append("facebook_link", inputs.facebook_link);
      formData.append("pinterest_link", inputs.pinterest_link);
      formData.append("youtube_link", inputs.youtube_link);
      formData.append("instagram_link", inputs.instagram_link);
      formData.append("mailbox_link", inputs.mailbox_link);
    }
    if (images.brand_logo) {
      formData.append("brand_logo", inputs.brand_logo);
    }
    if (images.brand_favicon) {
      formData.append("brand_favicon", inputs.brand_favicon);
    }
    if (images.footer_logo) {
      formData.append("footer_logo", inputs.footer_logo);
    }

    const result = await editPageApi(formData, params.page_slug);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
      navigate(-1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  React.useEffect(() => {
    if (params.page_slug) {
      if (state) {
        formatData(state);
      } else {
        getPageDetail();
      }
    }
    templatesListData();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
        <div className="col-12">
          <h2>{`${formType === "ADD" ? "Add" : "Edit"} Page`}</h2>
        </div>
      </div>
      <form onSubmit={formType == "ADD" ? handleSubmit : handleUpdate}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Page Title"
              variant="outlined"
              fullWidth
              name="sale_page_title"
              required
              value={inputs.sale_page_title}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Meta Title"
              variant="outlined"
              fullWidth
              name="meta_title"
              required
              value={inputs.meta_title}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Meta Keyword"
              variant="outlined"
              fullWidth
              name="meta_keywords"
              required
              value={inputs.meta_keywords}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Active Campaign List id"
              variant="outlined"
              fullWidth
              name="active_campaign_list_id"
              type="number"
              value={inputs.active_campaign_list_id}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Active Campaign Tag Id"
              variant="outlined"
              fullWidth
              type="number"
              name="active_campaign_tag_id"
              value={inputs.active_campaign_tag_id}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Choose Template
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="template"
                value={inputs.template}
                label="Choose Template"
                onChange={handleChange}
              >
                {templates.map((template) => {
                  return (
                    <MenuItem value={template._id}>
                      {template.template_name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Redirect User
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="redirect_user"
                value={inputs.redirect_user}
                label="Redirect User"
                onChange={handleChange}
              >
                <MenuItem value="thanks">Thanks Page</MenuItem>
                <MenuItem value="dashboard">Dashboard</MenuItem>
                <MenuItem value="programme">Programme</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Use general brand logo, footer logo and favicon?
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="is_general_brand_logo"
                required
                value={inputs.is_general_brand_logo}
                label="Use general brand logo, footer logo and favicon?"
                onChange={handleChange}
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Use general Social Media Links?
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="is_general_social_links"
                required
                value={inputs.is_general_social_links}
                label="Use general Social Media Links?"
                onChange={handleChange}
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </Select>
            </FormControl>
          </div>
          {inputs.is_general_social_links == "false" && (
            <>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Facebook Link"
                  variant="outlined"
                  fullWidth
                  name="facebook_link"
                  value={inputs.facebook_link}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Pinterest Link"
                  variant="outlined"
                  fullWidth
                  name="pinterest_link"
                  value={inputs.pinterest_link}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Instagram Link"
                  variant="outlined"
                  fullWidth
                  name="instagram_link"
                  value={inputs.instagram_link}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Youtube Link"
                  variant="outlined"
                  fullWidth
                  name="youtube_link"
                  value={inputs.youtube_link}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Mailbox Link"
                  variant="outlined"
                  fullWidth
                  name="mailbox_link"
                  value={inputs.mailbox_link}
                  onChange={handleChange}
                />
              </div>
            </>
          )}
          {inputs.is_general_brand_logo == false && (
            <>
              <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                <div className="row w-100 div-style ms-0 pt-0">
                  <div className="col-5">
                    <p className="">Brand Logo</p>
                    {/* <FormHelperText className="pt-0">
                ( "PNG",778 X 430)
              </FormHelperText> */}
                  </div>
                  <div className="col-2">
                    {formType === "EDIT" && oldImages.brand_logo ? (
                      <img src={s3baseUrl + oldImages.brand_logo} height="50" />
                    ) : images.brand_logo ? (
                      <img src={images.brand_logo} height="50" />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-5 text-end pt-2">
                    <label htmlFor="contained-button-fileFb">
                      <Input
                        accept="image/*"
                        id="contained-button-fileFb"
                        multiple
                        type="file"
                        name="brand_logo"
                        onChange={fileChangedHandler}
                      />

                      <Button
                        className="small-contained-button"
                        startIcon={<FileUploadIcon />}
                        component="span"
                      >
                        Upload
                      </Button>
                    </label>
                  </div>
                </div>
                {inputs?.image?.name == "" ? (
                  ""
                ) : (
                  <p className="text-secondary">{inputs?.image?.name}</p>
                )}
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                <div className="row w-100 div-style ms-0 pt-0">
                  <div className="col-5">
                    <p className="">Brand Favicon</p>
                    {/* <FormHelperText className="pt-0">
                ( "PNG",778 X 430)
              </FormHelperText> */}
                  </div>
                  <div className="col-2">
                    {formType === "EDIT" && oldImages.brand_favicon ? (
                      <img
                        src={s3baseUrl + oldImages.brand_favicon}
                        height="50"
                      />
                    ) : images.brand_favicon ? (
                      <img src={images.brand_favicon} height="50" />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-5 text-end pt-2">
                    <label htmlFor="contained-button-fileInstagram">
                      <Input
                        accept="image/*"
                        id="contained-button-fileInstagram"
                        multiple
                        type="file"
                        name="brand_favicon"
                        onChange={fileChangedHandler}
                      />

                      <Button
                        className="small-contained-button"
                        startIcon={<FileUploadIcon />}
                        component="span"
                      >
                        Upload
                      </Button>
                    </label>
                  </div>
                </div>
                {inputs?.image?.name == "" ? (
                  ""
                ) : (
                  <p className="text-secondary">{inputs?.image?.name}</p>
                )}
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                <div className="row w-100 div-style ms-0 pt-0">
                  <div className="col-5">
                    <p className="">Footer Logo</p>
                    <FormHelperText className="pt-0">
                      {/* ( "PNG",778 X 430) */}
                    </FormHelperText>
                  </div>
                  <div className="col-2">
                    {formType === "EDIT" && oldImages.footer_logo ? (
                      <img
                        src={s3baseUrl + oldImages.footer_logo}
                        height="50"
                      />
                    ) : images.footer_logo ? (
                      <img src={images.footer_logo} height="50" />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-5 text-end pt-2">
                    <label htmlFor="contained-button-fileTwitter">
                      <Input
                        accept="image/*"
                        id="contained-button-fileTwitter"
                        multiple
                        type="file"
                        name="footer_logo"
                        onChange={fileChangedHandler}
                      />

                      <Button
                        className="small-contained-button"
                        startIcon={<FileUploadIcon />}
                        component="span"
                      >
                        Upload
                      </Button>
                    </label>
                  </div>
                </div>
                {inputs?.image?.name == "" ? (
                  ""
                ) : (
                  <p className="text-secondary">{inputs?.image?.name}</p>
                )}
              </div>
            </>
          )}
          <div className="col-12 mt-4">
            <FormControl fullWidth>
              <TextField
                id="outlined-multiline-flexible"
                label="Meta Description"
                multiline
                rows={3}
                name="meta_description"
                value={inputs.meta_description}
                onChange={handleChange}
              />
            </FormControl>
          </div>
          <div className="col-12 mt-4">
            <FormControl fullWidth>
              <TextField
                id="outlined-multiline-flexible"
                label="Tracking Code Header"
                multiline
                rows={3}
                name="pixel_code_header"
                value={inputs.pixel_code_header}
                onChange={handleChange}
              />
            </FormControl>
          </div>
          <div className="col-12 mt-4">
            <FormControl fullWidth>
              <TextField
                id="outlined-multiline-flexible"
                label="Tracking Code Body"
                multiline
                rows={3}
                name="pixel_code_body"
                value={inputs.pixel_code_body}
                onChange={handleChange}
              />
            </FormControl>
          </div>
          <div className="col-12 mt-4">
            <h4>Thank You Page Content</h4>
            <TinyEditor
              setDetailDescription={setShortDescriptionCk}
              detailDescriptionCk={shortDescriptionCk}
              editorHeight={350}
            />
          </div>

          <div className="text-end mt-4">
            <button className="small-contained-button">
              {formType == "ADD" ? "Submit" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
