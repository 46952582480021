import * as React from "react";
import Box from "@mui/material/Box";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import FormHelperText from "@mui/material/FormHelperText";
//import Editor from "../../components/Editor/Editor";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
// import { VaultListing } from "src/DAL/Vault/Vault";
import Autocomplete from "@mui/material/Autocomplete";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Stack from "@mui/material/Stack";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  useNavigate,
  useParams,
  Link as RouterLink,
  useLocation,
} from "react-router-dom";
import { IconButton, Divider, CircularProgress } from "@mui/material";
// import { AddProgram } from "src/DAL/Programmes/Programmes";
import { object } from "prop-types";
import { menuList } from "src/DAL/Menu/Menu";
import { AddProgrammeApi } from "src/DAL/Programme/Programme";
import TinyEditor from "../../components/ckeditor/Ckeditor";
import { addQuotesApi } from "src/DAL/quotes/Quotes";
import {
  clientSettingApi,
  updateClientDashboardSettingApi,
} from "src/DAL/ClientSetting/ClientSetting";

// import { get_root_value } from "src/utils/domUtils";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const Input = styled("input")({
  display: "none",
});

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function ClientDashboardSetting() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [iswaiting, setiswaiting] = useState(false);
  const theme = useTheme();
  const [value, setValue] = React.useState("Controlled");
  const [personName, setPersonName] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setProfileImage] = React.useState();
  const [audioFile, setAudio] = React.useState();
  const [groups, setGroups] = React.useState([]);
  const [programList, setProgramList] = React.useState([]);
  const [menuLists, setMenuList] = React.useState([]);
  const [date, setDate] = React.useState(new Date());
  const [programName, setProgramName] = React.useState("");
  const [vaultName, setVaultName] = React.useState("");
  const [navItems, setNavitems] = React.useState([]);
  const [detailDescriptionCk, setDetailDescriptionCk] = React.useState("");
  const [footerLogo, setFooterLogo] = React.useState();
  const [brandFavIcon, setBrandFavIcon] = React.useState();

  const [inputs, setInputs] = React.useState({
    welcomeVideo: "",
    videoUrl: "",
    dcv1: "",
    dcv2: "",
    dcv3: "",
    dcv4: "",
    dcv5: "",
    dcv6: "",
    upcomingEventHeading: "",
    ClientReviewHeading: "",
    liveSectionButtonText: "",
    liveSectionButtonLink: "",
  });
  const clientDashboardData = async () => {
    setIsLoading(true);
    const result = await clientSettingApi();
    if (result.code == 200) {
      //console.log(result, "okokoko");
      let setting = result.content_setting.client_dashboard_setting;
      //console.log(setting, "llllllll");
      setInputs({
        ...inputs,
        ["welcomeVideo"]: setting.video_type,
        ["videoUrl"]: setting.video_url,
        ["dcv1"]: setting.v1_link,
        ["dcv2"]: setting.v2_link,
        ["dcv3"]: setting.v3_link,
        ["dcv4"]: setting.v4_link,
        ["dcv5"]: setting.v5_link,
        ["dcv6"]: setting.v6_link,
        ["upcomingEventHeading"]: setting.upcoming_events_heading,
        ["ClientReviewHeading"]: setting.client_reviews_heading,
        ["liveSectionButtonText"]: setting.live_section_button_text,
        ["liveSectionButtonLink"]: setting.live_section_button_link,
      });
      setIsLoading(false);
      setDetailDescriptionCk(setting.live_section_text);
    }
  };
  const handleSubmit = async (e) => {
    //console.log(detailDescriptionCk, "lolololo");
    e.preventDefault();

    let postData = {
      video_url: inputs.videoUrl,
      v1_link: inputs.dcv1,
      v2_link: inputs.dcv2,
      v3_link: inputs.dcv3,
      v4_link: inputs.dcv4,
      v5_link: inputs.dcv5,
      v6_link: inputs.dcv6,
      upcoming_events_heading: inputs.upcomingEventHeading,
      client_reviews_heading: inputs.ClientReviewHeading,
      live_section_button_text: inputs.liveSectionButtonText,
      live_section_button_link: inputs.liveSectionButtonLink,
      live_section_text: detailDescriptionCk,
      video_type: inputs.welcomeVideo,
      // banner_image_url:
      // cover_image_url:
    };
    //console.log(postData, "postDatapostDatapostData");
    let data = {
      client_dashboard_setting: postData,
    };

    setIsLoading(true);
    const result = await updateClientDashboardSettingApi(data);
    if (result.code === 200) {
      //console.log(result, "result");
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      //console.log(result, "error case");
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  //   //console.log(typeof audioFile, "values of audio to be send ");
  React.useEffect(() => {
    clientDashboardData();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  //console.log(detailDescriptionCk, "detailDescriptionCk");

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          {/* <button
            className="small-contained-button float-end mt-1"
            onClick={() => navigate(`/programmes/addreview/`)}
          >
            Add Programme Review
          </button> */}
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <h2>Client Dashboard</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">welcome Video</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="welcomeVideo"
              value={inputs.welcomeVideo}
              label="Welcome Video*"
              onChange={handleChange}
            >
              <MenuItem value="repeat">Welcome Video Repeating</MenuItem>
              <MenuItem value="once">Welcome Video For Once</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Video Url"
            variant="outlined"
            fullWidth
            name="videoUrl"
            value={inputs.videoUrl}
            onChange={handleChange}
          />
        </div>
        {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Dashbord Circle V1 "
            variant="outlined"
            fullWidth
            required
            name="dcv1"
            value={inputs.dcv1}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Dashbord Circle V2 "
            variant="outlined"
            fullWidth
            required
            name="dcv2"
            value={inputs.dcv2}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Dashbord Circle V3"
            variant="outlined"
            fullWidth
            required
            name="dcv3"
            value={inputs.dcv3}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Dashbord Circle V4"
            variant="outlined"
            fullWidth
            required
            name="dcv4"
            value={inputs.dcv4}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Dashbord Circle V5 "
            variant="outlined"
            fullWidth
            required
            name="dcv5"
            value={inputs.dcv5}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Dashbord Circle V6 "
            variant="outlined"
            fullWidth
            required
            name="dcv6"
            value={inputs.dcv6}
            onChange={handleChange}
          />
        </div> */}
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Upcomming Events Heading "
            variant="outlined"
            fullWidth
            name="upcomingEventHeading"
            value={inputs.upcomingEventHeading}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Client Reviews Heading"
            variant="outlined"
            fullWidth
            name="ClientReviewHeading"
            value={inputs.ClientReviewHeading}
            onChange={handleChange}
          />
        </div>
        {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Live Section Button Text"
            variant="outlined"
            fullWidth
            required
            name="liveSectionButtonText"
            value={inputs.liveSectionButtonText}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Live Section Button Link"
            variant="outlined"
            fullWidth
            required
            name="liveSectionButtonLink"
            value={inputs.liveSectionButtonLink}
            onChange={handleChange}
          />
        </div> */}
        {/* <div className="col-12 mt-5">
          <TinyEditor
            setDetailDescription={setDetailDescriptionCk}
            detailDescriptionCk={detailDescriptionCk}
          />
        </div> */}
        <div className="text-end mt-4">
          <button onClick={handleSubmit} className="small-contained-button">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
