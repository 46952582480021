import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  TextField,
} from "@mui/material";
import React, { useRef, useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import {
  _add_wheel_of_life,
  _delete_wheel_of_life,
  _get_wheel_of_life,
  _update_wheel_of_life,
} from "src/DAL/QuestionSurvey/QuestionSurvey";
import { useSnackbar } from "notistack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect } from "react";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { project_url } from "src/config/config";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  addMenuApi,
  editMenuApi,
  getMenuListApi,
} from "src/DAL/MenuItems/MenuItems";
import { pagesListingApi } from "src/DAL/WebsitePages/websitepages";
import MenuList from "./MenuList";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export const MenuItems = () => {
  const params = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [openDelete, setOpenDelete] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const ref = useRef(null);
  const [selectedQuestion, setSelectedQuestion] = useState({});
  const [formAction, setFormAction] = useState("ADD");
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [questionsList, setQuestionsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editClicked, setEditClicked] = useState(false);
  const [mainHeading, setMainHeading] = useState("");
  const [pageData, setPageData] = useState([]);
  const [inputList, setInputList] = useState([
    {
      title: "",
      item_url_for: "custom",
      item_url: "",
      is_new_tab: false,
      page: "",
      section_id: "",
      status: true,
      new_child_menu: false,
      items: [],
    },
  ]);

  const classes = useStyles();
  const [inputs, setInputs] = useState({
    menu_heading: "",
    item_url_for: "page",
    new_tab: false,
    new_child_menu: false,
    item_url: "",
    page: "",
  });

  const handleClick = () => {
    setIsOpenForm(true);
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };
  const getPageList = async () => {
    let result = await pagesListingApi();
    if (result.code == 200) {
      //console.log(result, "okokoko");
      setPageData(result.Sale_page);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
    if (inputList.length < 1) {
      setInputList([
        {
          title: "",
          item_url_for: "",
          item_url: "",
          is_new_tab: "",
          status: true,
          new_child_menu: false,
        },
      ]);
    }
  };
  // handle click event of the Add button
  const handleAddClick = (index) => {
    let newIndex = inputList.length + 1;
    setInputList([
      ...inputList.slice(0, index),
      {
        title: "",
        item_url_for: "custom",
        item_url: "",
        is_new_tab: false,
        page: "",
        section_id: "",
        status: true,
        new_child_menu: false,
      },
      ...inputList.slice(index),
    ]);
  };
  const handleAddNestedMenuClick = (parentIndex, index) => {
    const list = [...inputList];
    let newItemObj = {
      title: "",
      item_url_for: "custom",
      item_url: "",
      is_new_tab: false,
      section_id: "",
      new_child_menu: false,
      page: "",
      status: true,
    };
    list[parentIndex]["items"].push(newItemObj);
    setInputList(list);
  };
  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);

    setInputList(list);
  };
  const handleRemoveNestedClick = (parent, index) => {
    // console.log(parent, index);
    const list = [...inputList];
    const itemsList = list[parent];
    // console.log(itemsList, "itemsList");
    itemsList.items.splice(index, 1);
    setInputList(list);
  };

  const expandArea = (index) => {
    let temp_state = [...questionsList];
    let temp_element = { ...temp_state[index] };
    if (temp_element.isExpanded === false) {
      temp_element.isExpanded = true;
    } else {
      temp_element.isExpanded = false;
    }
    temp_state[index] = temp_element;
    setQuestionsList(temp_state);
  };

  // handle input change
  const handleInputChange = (e, index, parentIndex) => {
    const { name, value } = e.target;

    const list = [...inputList];
    const found = pageData.find((element) => element._id == value);
    if (parentIndex !== undefined) {
      let selected_index = list[parentIndex];
      selected_index["items"][index][name] = value;
      if (name === "page") {
        selected_index["items"][index]["item_url"] =
          project_url + found.page_component_name;
      } else {
        // selected_index["items"][index]["item_url"] = "";
        // selected_index["items"][index]["page"] = "";
        selected_index["items"][index][name] = value;
      }

      setInputList(list);
    } else {
      list[index][name] = value;
      if (name === "page" || name === "scroll_section") {
        list[index]["item_url"] = project_url + found.page_component_name;
      } else {
        // list[index]["item_url"] = "";
        // list[index]["page"] = "";
        list[index][name] = value;
      }

      setInputList(list);
    }
  };

  const handleChecked = (e, i) => {
    const { name, checked } = e.target;
    const list = [...inputList];
    if (list[i][name] === true) {
      list[i][name] = false;
    } else {
      list[i][name] = true;
    }
    setInputList(list);
  };

  const handleRemoveValues = () => {
    setMainHeading("");
    setInputList([
      {
        title: "",
        item_url_for: "custom",
        item_url: "",
        is_new_tab: false,
        page: "",
        status: true,
        new_child_menu: false,
        items: [],
      },
    ]);
    // setInputList([{ option: "", checked: false }]);
    setFormAction("ADD");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let finalData = {};

    inputList.map((item) => {
      if (item.title == "") {
      } else {
        if (item.item_url_for === "custom") {
          delete item.page;
          delete item.section_id;
        }
        if (item.item_url_for === "page") {
          delete item.section_id;
        }
        delete item._id;
        delete item.new_child_menu;

        item?.items?.map((nestedItem) => {
          if (nestedItem.item_url_for === "custom") {
            delete item.page;
            delete nestedItem.page;
            delete nestedItem.section_id;
          }
          if (nestedItem.item_url_for === "page") {
            delete nestedItem.section_id;
          }
          delete nestedItem._id;
          //console.log("nested page delete");
        });
        setIsSubmitting(true);
        finalData = {
          menu_title: mainHeading,
          menu_items: inputList,
        };
      }
    });

    // console.log(inputList, "inputs");
    // console.log(finalData, "finalData");
    const result = state
      ? await editMenuApi(finalData, state?.object._id)
      : await addMenuApi(finalData);
    if (result.code == 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsSubmitting(false);
      setMainHeading("");
      setInputList([
        {
          title: "",
          item_url_for: "custom",
          item_url: "",
          is_new_tab: true,
          page: "",
          status: true,
          new_child_menu: false,
          items: [],
        },
      ]);
      navigate("/menu");
      handleCloseForm();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsSubmitting(false);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    let error = "";
    const formData = new FormData();
    formData.append("question_statement", inputs.question_statement);
    formData.append("question_type", inputs.question_type);
    formData.append("order", selectedQuestion.order);

    if (params && params.page_id) {
      formData.append("created_for", "sale_page");
      formData.append("page_id", params.page_id);
    } else {
      formData.append("created_for", "general");
    }
    // array to send in api
    let options = [];
    inputList.map((item, index) => {
      if (item.option === "") {
        error = `Option ${index + 1} is required`;
        return;
      }
      options.push(item.option);
    });
    formData.append("options", JSON.stringify(options));

    if (error) {
      enqueueSnackbar(error, { variant: "error" });
      setIsSubmitting(false);
    } else {
      const result = await _update_wheel_of_life(
        selectedQuestion._id,
        formData
      );
      if (result.code === 200) {
        getMenuItemsList(params?.page_id);
        handleRemoveValues();
        setIsSubmitting(false);
        enqueueSnackbar(result.message, { variant: "success" });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsSubmitting(false);
      }
    }
  };

  const getMenuItemsList = async () => {
    const result = await getMenuListApi();

    if (result.code === 200) {
      let menuData = result.menu;
      setQuestionsList(menuData);
      setIsLoading(false);
    } else {
      // enqueueSnackbar(result.message, { variant: "error" });

      setIsLoading(false);
    }
  };

  const handleAgreeDelete = (value) => {
    setSelectedQuestion(value);
    setOpenDelete(true);
  };

  const handleEdit = (value) => {
    setSelectedQuestion(value);
    handleClick();
    setFormAction("EDIT");
    setInputs({
      question_type: value.question_type,
      scaling_min: value.scaling_min,
      scaling_max: value.scaling_max,
      question_statement: value.question_statement,
      scaling_color: value.scaling_color,
      scaling_main_heading: value.scaling_main_heading,
    });
    setInputList(() => {
      return value.options.map((option) => {
        return {
          option: option,
          checked: false,
        };
      });
    });
  };

  const handleCancel = () => {
    handleRemoveValues();
  };
  const handleManageNestedMenu = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
    if (value == true) {
      const list = [...inputList];
      let nestedItem = [
        {
          title: "",
          item_url_for: "",
          item_url: "",
          is_new_tab: "",
          page: "",
          status: true,
        },
      ];
      list[index].items = nestedItem;
      setInputList(list);
    }
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await _delete_wheel_of_life(selectedQuestion._id);
    if (result.code === 200) {
      getMenuItemsList(params?.page_id);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleCloseForm = () => {
    setIsOpenForm(false);
    handleRemoveValues();
    navigate("/menu");
  };
  let EditMenuData = state?.object;

  useEffect(() => {
    if (params.id !== undefined) {
      //console.log(EditMenuData?.menu_items, "okokokok");
      setInputList(EditMenuData?.menu_items);
      setMainHeading(EditMenuData?.menu_title);
      setIsOpenForm(true);
      setFormAction("EDIT");
    }
  }, [params.id !== undefined]);

  useEffect(() => {
    getMenuItemsList();
    getPageList();
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  //console.log(editClicked, "params.id");

  return (
    <div className="container">
      <div className="row mb-4">
        <div className="col-12">
          {
            <IconButton
              className="back-screen-button mb-4"
              onClick={handleCloseForm}
            >
              <ArrowBackIcon />
            </IconButton>
          }
        </div>
        <div className="col-12 col-md-6">
          <h1>Menus</h1>
        </div>
        {/* <div className="col-12 col-md-6">
          <button
            className="small-contained-button float-end mt-1"
            onClick={handleClick}
          >
            Add Menus
          </button>
        </div> */}
      </div>
      {
        <form ref={ref}>
          <div className="row question-background mt-5">
            <div className="col-lg-12 col-md-12 col-sm-12 mt-3 mb-3">
              <TextField
                id="outlined-basic"
                label="Menu Heading"
                variant="outlined"
                fullWidth
                required
                value={mainHeading}
                onChange={(e) => setMainHeading(e.target.value)}
              />
            </div>
          </div>
          <div className="row question-background mt-4">
            {
              <>
                {inputList?.map((x, parentIndex) => {
                  return (
                    <>
                      <div className="col-lg-12 col-md-12 col-sm-12 mt-3 mb-3 d-flex">
                        <div className="col-10">
                          <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 ">
                              <TextField
                                id="outlined-basic"
                                label="Title"
                                variant="outlined"
                                fullWidth
                                name="title"
                                value={x.title}
                                onChange={(e) =>
                                  handleInputChange(e, parentIndex)
                                }
                              />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  Item Url For
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  name="item_url_for"
                                  value={x.item_url_for}
                                  label="Item Url For *"
                                  onChange={(e) =>
                                    handleInputChange(e, parentIndex)
                                  }
                                >
                                  <MenuItem value="page">Page</MenuItem>
                                  <MenuItem value="custom">Custom</MenuItem>
                                  <MenuItem value="scroll_section">
                                    Scroll Section
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </div>
                            {(x.item_url_for == "page" ||
                              x.item_url_for == "scroll_section") && (
                              <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">
                                    Select Page *
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="page"
                                    value={x.page}
                                    label="Select Page *"
                                    onChange={(e) =>
                                      handleInputChange(e, parentIndex)
                                    }
                                  >
                                    {pageData.map((value, i) => {
                                      return (
                                        <MenuItem value={value._id}>
                                          {value?.sale_page_title}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </div>
                            )}
                            <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                              <TextField
                                id="outlined-basic"
                                label="Item URL"
                                variant="outlined"
                                fullWidth
                                name="item_url"
                                value={x.item_url}
                                onChange={(e) =>
                                  handleInputChange(e, parentIndex)
                                }
                              />
                            </div>
                            {x.item_url_for == "scroll_section" && (
                              <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                                <TextField
                                  id="outlined-basic"
                                  label="Scroll Section Id"
                                  variant="outlined"
                                  fullWidth
                                  name="section_id"
                                  value={x.section_id}
                                  onChange={(e) =>
                                    handleInputChange(e, parentIndex)
                                  }
                                />
                              </div>
                            )}
                            <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  Menu Open In New Tab
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  name="is_new_tab"
                                  value={x.is_new_tab}
                                  label="Menu Open In New Tab"
                                  onChange={(e) =>
                                    handleInputChange(e, parentIndex)
                                  }
                                >
                                  <MenuItem value={true}>Yes</MenuItem>
                                  <MenuItem value={false}>No</MenuItem>
                                </Select>
                              </FormControl>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  Status
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  name="status"
                                  value={x.status}
                                  label="Status "
                                  onChange={(e) =>
                                    handleInputChange(e, parentIndex)
                                  }
                                >
                                  <MenuItem value={true}>Active</MenuItem>
                                  <MenuItem value={false}>Inactive</MenuItem>
                                </Select>
                              </FormControl>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  New Child Menu
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  name="new_child_menu"
                                  value={
                                    x.new_child_menu
                                      ? x.new_child_menu
                                      : "false"
                                  }
                                  label="New Child Menu"
                                  onChange={(e) =>
                                    handleManageNestedMenu(e, parentIndex)
                                  }
                                >
                                  <MenuItem value={true}>Yes</MenuItem>
                                  <MenuItem value={false}>No</MenuItem>
                                </Select>
                              </FormControl>
                            </div>
                          </div>
                          {x.items == undefined
                            ? ""
                            : x.items.length > 0 && (
                                <div className="row mt-3 mb-3">
                                  {x.items.map((nested, i) => {
                                    return (
                                      <>
                                        <div className="col-10 mt-3 card p-4 nested-child-menu">
                                          <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12 ">
                                              <TextField
                                                id="outlined-basic"
                                                label="Title"
                                                variant="outlined"
                                                fullWidth
                                                name="title"
                                                value={nested.title}
                                                onChange={(e) =>
                                                  handleInputChange(
                                                    e,
                                                    i,
                                                    parentIndex
                                                  )
                                                }
                                              />
                                            </div>
                                            <div className="col-3 col-sm-6 mt-3">
                                              <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">
                                                  Item Url For
                                                </InputLabel>
                                                <Select
                                                  labelId="demo-simple-select-label"
                                                  id="demo-simple-select"
                                                  name="item_url_for"
                                                  value={nested.item_url_for}
                                                  label="Item Url For"
                                                  onChange={(e) =>
                                                    handleInputChange(
                                                      e,
                                                      i,
                                                      parentIndex
                                                    )
                                                  }
                                                >
                                                  <MenuItem value="page">
                                                    Page
                                                  </MenuItem>
                                                  <MenuItem value="custom">
                                                    Custom
                                                  </MenuItem>
                                                  <MenuItem value="scroll_section">
                                                    Scroll Section
                                                  </MenuItem>
                                                </Select>
                                              </FormControl>
                                            </div>
                                            {(nested.item_url_for == "page" ||
                                              nested.item_url_for ==
                                                "scroll_section") && (
                                              <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                                                <FormControl fullWidth>
                                                  <InputLabel id="demo-simple-select-label">
                                                    Select Page *
                                                  </InputLabel>
                                                  <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    name="page"
                                                    value={nested.page}
                                                    label="Select Page *"
                                                    onChange={(e) =>
                                                      handleInputChange(
                                                        e,
                                                        i,
                                                        parentIndex
                                                      )
                                                    }
                                                  >
                                                    {pageData.map(
                                                      (value, i) => {
                                                        return (
                                                          <MenuItem
                                                            value={value._id}
                                                          >
                                                            {
                                                              value?.sale_page_title
                                                            }
                                                          </MenuItem>
                                                        );
                                                      }
                                                    )}
                                                  </Select>
                                                </FormControl>
                                              </div>
                                            )}
                                            {nested.item_url_for ==
                                              "scroll_section" && (
                                              <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                                                <TextField
                                                  id="outlined-basic"
                                                  label="Scroll Section Id"
                                                  variant="outlined"
                                                  fullWidth
                                                  name="section_id"
                                                  value={nested.section_id}
                                                  onChange={(e) =>
                                                    handleInputChange(
                                                      e,
                                                      i,
                                                      parentIndex
                                                    )
                                                  }
                                                />
                                              </div>
                                            )}
                                            <div className="col-3 col-sm-6 mt-3">
                                              <TextField
                                                id="outlined-basic"
                                                label="Item URL"
                                                variant="outlined"
                                                fullWidth
                                                name="item_url"
                                                value={nested.item_url}
                                                onChange={(e) =>
                                                  handleInputChange(
                                                    e,
                                                    i,
                                                    parentIndex
                                                  )
                                                }
                                              />
                                            </div>

                                            <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                                              <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">
                                                  Menu Open In New Tab
                                                </InputLabel>
                                                <Select
                                                  labelId="demo-simple-select-label"
                                                  id="demo-simple-select"
                                                  name="is_new_tab"
                                                  value={nested.is_new_tab}
                                                  label="Menu Open In New Tab *"
                                                  onChange={(e) =>
                                                    handleInputChange(
                                                      e,
                                                      i,
                                                      parentIndex
                                                    )
                                                  }
                                                >
                                                  <MenuItem value={true}>
                                                    Yes
                                                  </MenuItem>
                                                  <MenuItem value={false}>
                                                    No
                                                  </MenuItem>
                                                </Select>
                                              </FormControl>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                                              <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">
                                                  Status
                                                </InputLabel>
                                                <Select
                                                  labelId="demo-simple-select-label"
                                                  id="demo-simple-select"
                                                  name="status"
                                                  value={nested.status}
                                                  label="status *"
                                                  onChange={(e) =>
                                                    handleInputChange(
                                                      e,
                                                      i,
                                                      parentIndex
                                                    )
                                                  }
                                                >
                                                  <MenuItem value={true}>
                                                    Active
                                                  </MenuItem>
                                                  <MenuItem value={false}>
                                                    Inactive
                                                  </MenuItem>
                                                </Select>
                                              </FormControl>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-2">
                                          <AddCircleOutlineIcon
                                            className="question-survey"
                                            onClick={() =>
                                              handleAddNestedMenuClick(
                                                parentIndex,
                                                i
                                              )
                                            }
                                          />
                                          {x.items.length !== 0 && (
                                            <RemoveCircleOutlineIcon
                                              className="question-survey"
                                              onClick={() =>
                                                handleRemoveNestedClick(
                                                  parentIndex,
                                                  i
                                                )
                                              }
                                            />
                                          )}
                                        </div>
                                      </>
                                    );
                                  })}
                                </div>
                              )}
                        </div>

                        <div className="col-2">
                          <AddCircleOutlineIcon
                            className="question-survey"
                            onClick={() => handleAddClick(parentIndex + 1)}
                          />
                          {inputList.length !== 1 && (
                            <RemoveCircleOutlineIcon
                              className="question-survey"
                              onClick={() => handleRemoveClick(parentIndex)}
                            />
                          )}
                        </div>
                      </div>
                    </>
                  );
                })}
              </>
            }
          </div>
          <div className="col-12 mt-4 text-end mb-4">
            <button
              className="small-contained-button"
              onClick={formAction === "ADD" ? handleSubmit : handleSubmit}
            >
              {formAction === "ADD"
                ? isSubmitting
                  ? "Submitting"
                  : "Add Menu"
                : isSubmitting
                ? "Updating"
                : "Update"}
            </button>
            {/* {formAction === "EDIT" && (
              <button
                className="small-contained-button ms-2"
                onClick={handleCancel}
              >
                Cancel
              </button>
            )} */}
            <button
              className="small-contained-button ms-2"
              onClick={handleCloseForm}
            >
              Close
            </button>
          </div>
        </form>
      }
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
    </div>
  );
};
